<template>
  <div id="app">
    <router-view />
    <ImageFilter />
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import Gen from '@/libs/Gen'
import _ from 'lodash'

export default {
  extends:GlobalVue,
  async mounted(){
    let variable = Gen.getStorage("bo_variable", {})
    _.forEach(variable, (v, k) => { this.$root[k] = v })
    
    try {
      let res = await Gen.apiRest("/variable", {})
      Gen.putStorage("bo_variable", res.data)
      _.forEach(res.data, (v, k) => { this.$set(this.$root, k, v) })
    }catch{
      console.log('error')
    }
  },
}
</script>