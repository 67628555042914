<template>
  <b-row class="page-titles">
    <b-col md="5" class="align-self-center">
      <h4 class="text-themecolor">{{ pageTitle || defPageTitle }}</h4>
    </b-col>
    <b-col md="7" class="align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center">
        <template v-if="hasBreadCrumb">
          <b-breadcrumb>
            <b-breadcrumb-item :active="crumbs.length == 0">{{ pageTitle || defPageTitle }}</b-breadcrumb-item>
            <template v-if="crumbs.length > 0">
              <b-breadcrumb-item
                v-for="(crumb, key) in crumbs"
                :key="key"
                :active="key == (crumbs.length - 1)"
              >{{ crumb }}</b-breadcrumb-item>
            </template>
          </b-breadcrumb>
        </template>
        
        <template>
          <b-button
            variant="success"
            v-if="(hasCta && !isList ) || (!isList && ctaList)"
            class="m-l-15 btn-rounded"
            :to="{ name: routeName }"
          >
            <i class="fas fa-list mr-2" />
            {{ objName || pageTitle || defPageTitle }} List
          </b-button>
          <b-button
            variant="success"
            v-else-if="hasCta && $parent.moduleRole('add')"
            class="m-l-15 btn-rounded"
            :to="{ name: routeName, params: { pageSlug: 'add' } }"
          >
            <i class="fa fa-plus-circle mr-2" />
            Add {{ objName || pageTitle || defPageTitle }}
          </b-button>
          <slot v-if="hasCta" name="additionalCta" />
        </template>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
const _ = require('lodash')

export default {
  extends: GlobalVue,
  computed: {
    defPageTitle() { return _.startCase(this.$route.name) },
    pageTitle() { return this.$route.meta.pageTitle },
    breadcrumbs() { return this.$route.meta.breadcrumbs },
    objName() { return this.$route.meta.objName },
    hasCta() { return this.$route.meta.hasCta },
    ctaList() { return this.$route.meta.ctaList },
    hasBreadCrumb() { return this.$route.meta.hasBreadCrumb },
    isList() { return !this.$route.params.pageSlug },
    isAdd() { return this.$route.params.pageSlug == 'add' },
    routeName() { return this.$route.name },
    crumbs() {
      let path = this.$route.path.split('/')
      let mapped = path.filter((el, k) => k > 1).map(el => {
        return el >>> 0 === parseFloat(el) ? 'Edit' : _.startCase(el)
      })
      return mapped
    }
  }
}
</script>