const Config = {
  app:{
    webaname:'GAMS',
    company:'PT Lingkar Sembilan Titian Media',
    copyright:()=>{
      let date = new Date()
      if(date.getFullYear()>2022){
        return '2022-'+date.getFullYear()
      }else{
        return '2022'
      }
    }
  },
  encrypt_key: "#@GAMS2021!!",
  mr:{
    StatusOptions: [
      { text: 'Active', value: 'Y' },
      { text: 'Inactive', value: 'N' },
    ],
    SurveyorOptions: [
      { text: 'Yes', value: 'Y' },
      { text: 'No', value: 'N' },
    ],
    AbleOptions: [
      { text: 'Enable', value: 'Y' },
      { text: 'Disable', value: 'N' },
    ],
    mrCatType:[
      // { text: 'All', value: 'All' },
      { text: 'Article', value: 'ARTICLE' },
      { text: 'News', value: 'NEWS' },
    ]
  },
  wording:{
    detectUpdate: 'Perubahan data belum di simpan.'
  },
  toolsBasicCK: [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
  ],
  dataShownOptions: [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '30', value: 30 },
  ],
  dataShown:10
}
export default Config