<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title title-tag="h5">{{ title }}</b-card-title>
      <div class="filters" v-if="this.$slots.filters">
        <slot name="filters" />
      </div>
    </b-card-header>
    <template v-if="useTable">
      <slot />
    </template>
    <b-card-body v-else>
      <slot />
    </b-card-body>
    <b-card-footer v-if="this.$slots.footer">
      <slot name="footer" />
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    useTable: {
      type: Boolean,
    }
  }
}
</script>
<style lang="scss" scoped>
  .card-header{
    display: flex;
    .card-title{flex: 1;}
    .filters{flex: 4;padding-left: 16px;}
  }
</style>