import Vue from 'vue'
import Vuex from 'vuex'

import Gen from '@/libs/Gen'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    // variables
    production: process.env.VUE_APP_MODE == "production",
    baseUrl: process.env.VUE_APP_MODE == 'local' ? location.origin : process.env.VUE_APP_BASE_URL,
    foBaseUrl: process.env.VUE_APP_MODE == 'local' ? location.origin : process.env.VUE_APP_FO_BASE_URL,
    apiUrl: process.env.VUE_APP_API_URL,
    webTitle: process.env.VUE_APP_TITLE,
    userToken: null,
    user: {},
    data: {},
    boSidebarOpen: true,
  },
  getters: {
    // computed
    authenticated(state) {
      return state.userToken && state.user?.id
    },
    canManageAll(state, getters) {
      if (getters['authenticated']) {
        return state.user?.canManageAll == 'Y'
      } else {
        return false
      }
    },
  },
  mutations: {
    // update action
    setUserToken(state, data = {}) { state.userToken = data.token },
    updateData(state, data = {}) { state.data = data.user },
    updateUser(state, data = {}) {
      state.user = data.user
      localStorage.setItem('u_menu', JSON.stringify(state.user?.menuRole || []))
      localStorage.setItem('u_menu_all', state.user?.levelId == 1 ? 'Y' : 'N')
    },
    updateUserToken(state, data = {}) {
      state.userToken = data.token
      Gen.setCookie("u_auth", data.token, 30 * 3)
    },
    logoutUserToken: function (state) {
      state.userToken = null
      state.user = {}
      Gen.setCookie("u_auth", "")
      localStorage.removeItem('u_menu')
      localStorage.removeItem('u_menu_all')
    },
    toggleBoSidebar(state, val){
      if (window.innerWidth < 768) {
        document.querySelector('body').classList.toggle('show-sidebar')
        document.querySelector('.toggle-nav i').classList.toggle('ti-close')
      } else {
        document.querySelector('body').classList.toggle('mini-sidebar')
      }
      val !== undefined ? state.boSidebarOpen = val : state.boSidebarOpen = !state.boSidebarOpen
    }
  },
  actions: {
    async attempt({ commit }) {
      commit('updateUserToken', { token: Gen.getCookie('u_auth') })
      try {
        let resp = await Gen.apiRest("/get/profile")
        resp = resp.data
        if (resp.status == "success") {
          commit('updateUserToken', { token: resp.data.token })
          commit('updateUser', { user: resp.data.user })
        } else {
          commit('updateUser', {})
        }
      } catch (error) {
        commit('updateUser', {})
      }
    }
  },
  strict: process.env.DEV,
})


export default store
