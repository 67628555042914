<template>
  <div class="d-flex">
    <b-input-group>
      <b-form-input v-model="value" placeholder="Type and press enter..." />
      <template #append>
        <b-button variant="success">
          <i class="fas fa-search"></i>
        </b-button>
      </template>
    </b-input-group>
    <b-button
      class="ml-1 d-inline-flex align-items-center"
      variant="outline-success"
      :to="{name: this.$route.name}"
    >Reset</b-button>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  }
}
</script>
