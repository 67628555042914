const bo_routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/backend/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard/:pageSlug?',
    name: 'BoDashboard',
    component: () => import('@/views/backend/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'account-setting',
    name: 'BoAccountSetting',
    component: () => import('@/views/backend/BoAccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/BoUser/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'BoUserLevel',
    component: () => import('@/views/backend/BoUserLevel/List.vue'),
    meta: {
      menu: 'UserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'settings',
    name: 'BoWebSettings',
    component: () => import('@/views/backend/BoWebSettings/BoWebSettings.vue'),
    meta: {
      pageTitle: 'App Settings',
      menu: 'BoWebSetting',
      objName: 'App Settings'
    }
  },
  {
    path: 'audittrail/:pageSlug?/:pageId?',
    name: 'BoAuditTrail',
    component: () => import('@/views/backend/BoAuditTrail/AuditTrail.vue'),
    meta: {
      pageTitle: 'Audit Trail',
      menu: 'BoAuditTrail',
      objName: 'Audit Trail'
    }
  },
  {
    path: 'vehicle/:pageSlug?/:pageId?',
    name: 'BoVehicle',
    component: () => import('@/views/backend/Vehicle/List.vue'),
    meta: {
      pageTitle: 'Vehicle',
      hasCta: 'true',
      menu: 'BoVehicle',
      objName: 'Vehicle'
    }
  },
  {
    path: 'cashless/:pageSlug?/:pageId?',
    name: 'BoCashless',
    component: () => import('@/views/backend/CashlessType/List.vue'),
    meta: {
      pageTitle: 'Cashless Type',
      hasCta: 'true',
      menu: 'BoCashless',
      objName: 'Cashless Type'
    }
  },
  {
    path: 'booking-transport/:pageSlug?/:pageId?',
    name: 'BoBookingTransport',
    component: () => import('@/views/backend/BookTransport/List.vue'),
    meta: {
      pageTitle: 'Booking Transport',
      hasCta: 'true',
      menu: 'BoBookingTransport',
      objName: 'Booking Transport'
    }
  },
  {
    path: 'new-schedules/:pageSlug?/:pageId?',
    name: 'BoNewSchedules',
    component: () => import('@/views/backend/NewSchedules/List.vue'),
    meta: {
      pageTitle: 'New Schedules',
      menu: 'BoNewSchedules',
      objName: 'New Schedules'
    }
  },
  {
    path: 'on-going/:pageSlug?/:pageId?',
    name: 'BoTransportOnGoing',
    component: () => import('@/views/backend/OnGoingSchedule/List.vue'),
    meta: {
      pageTitle: 'On Going Schedule',
      menu: 'BoTransportOnGoing',
      objName: 'On Going Schedule'
    }
  },
  {
    path: 'notification/:pageSlug?',
    name: 'BoNotification',
    component: () => import('@/views/backend/BoNotification.vue'),
    meta: {
      menu: 'BoNotification',
      pageTitle: 'Notification',
      objName: 'Notification',
    },
  },
  {
    path: 'task-item/:pageSlug?/:pageId?',
    name: 'BoTaskItem',
    component: () => import('@/views/backend/TaskItem/List.vue'),
    meta: {
      pageTitle: 'Task Item',
      menu: 'BoTaskItem',
      objName: 'Task Item'
    }
  },
  {
    path: 'task-setup/:pageSlug?/:pageId?',
    name: 'BoTaskSetup',
    component: () => import('@/views/backend/TaskSetup/Form.vue'),
    meta: {
      pageTitle: 'Task Setup',
      menu: 'BoTaskSetup',
      objName: 'Task Setup'
    }
  },
  {
    path: 'activity/:pageSlug?/:pageId?',
    name: 'BoTaskActivity',
    component: () => import('@/views/backend/TaskActivity/List.vue'),
    meta: {
      pageTitle: 'Task Activity',
      menu: 'BoTaskActivity',
      objName: 'Task Activity'
    }
  },
  {
    path: 'evaluation-task/:pageSlug?/:pageId?',
    name: 'BoTaskEvaluation',
    component: () => import('@/views/backend/TaskEvaluation/List.vue'),
    meta: {
      pageTitle: 'Evaluation Task',
      menu: 'BoTaskEvaluation',
      objName: 'Evaluation Task'
    }
  },
  {
    path: 'history-task/:pageSlug?/:pageId?',
    name: 'BoHistoryTask',
    component: () => import('@/views/backend/HistoryTask/List.vue'),
    meta: {
      pageTitle: 'History Task',
      menu: 'BoHistoryTask',
      objName: 'History Task'
    }
  },
]
export default bo_routes